<template>
  <el-drawer :visible.sync="visible" :with-header="false" @close="resetForm('form')" size="600px">
    <div class="jue-drawer-header">
      <div class="jue-drawer-title">查看客户信息</div>
      <div class="jue-drawer-btns">
        <i @click="visible = false" class="el-icon-close"></i>
      </div>
    </div>

    <div class="jue-drawer-body">
      <el-form :model="form" :rules="rules" label-position="right" label-width="120px" ref="form">
        <el-form-item label="客户名称：" prop="name">
          <strong v-if="type == 'view'">{{form.name}}</strong>
          <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-else v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="站点密钥：" prop="key">
          <div v-if="type == 'add'">
            <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.key"></el-input>
            <!-- <span class="text-desc">
              子网站访问域名为：https://
              <strong style="color:#409EFF;">{{form.key}}</strong>.jue.sh
            </span>-->
          </div>
          <strong v-else>
            <span style="color:#409EFF;">{{form.key}}</span>
          </strong> (登陆时填写)
        </el-form-item>
        <el-form-item label="服务截止时间：" prop="tmp_expired_date">
          <strong v-if="type == 'view'">{{form.tmp_expired_date}}</strong>
          <el-date-picker
            @change="dateChange"
            placeholder="选择日期"
            prefix-icon="el-icon-date"
            type="date"
            v-else
            v-model="form.tmp_expired_date"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>

        <el-form-item class="form-avatar" label="客户头像：">
          <el-avatar :src="form.logo" class="avatar" icon="el-icon-user-solid"></el-avatar>
          <span class="btn">
            <el-upload
              :data="myData"
              :headers="myHeaders"
              :on-success="uploadSuccess"
              :show-file-list="false"
              action="https://api.qk.zhongheinfo.com/index/upload"
              name="file"
            >
              <el-button type="text" v-if="type != 'view'">点击上传</el-button>
            </el-upload>
          </span>
        </el-form-item>

        <el-form-item label="客户描述：">
          <strong v-if="type == 'view'">{{form.memo}}</strong>
          <el-input :rows="2" placeholder="请输入" type="textarea" v-else v-model="form.memo"></el-input>
        </el-form-item>

        <!-- <el-form-item label="设置管理员：" prop="mobile" v-if="type != 'view'">
            <el-input placeholder="用户名：客户手机号码" suffix-icon="el-icon-edit" v-model="form.mobile"></el-input>
          </el-form-item>

          <el-form-item prop="password" v-if="type != 'view'">
            <el-input placeholder="登陆密码：密码由8-20位字母或数字组成" suffix-icon="el-icon-view" v-model="form.password"></el-input>
        </el-form-item>-->

        <el-form-item label="设置管理员：" prop="mobile" v-if="type == 'add'">
          <el-input placeholder="用户名：客户手机号码" suffix-icon="el-icon-edit" v-model="form.mobile"></el-input>
        </el-form-item>

        <el-form-item prop="password" v-if="type == 'add'">
          <el-input
            placeholder="登陆密码：密码由8-20位字母或数字组成"
            suffix-icon="el-icon-view"
            v-model="form.password"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="form-footer" v-has="'site-create'">
      <div v-if="type == 'view'">
        <el-button @click="type='update'" plain type="primary">修改</el-button>
        <el-popover placement="top" style="margin-left:20px;">
          <p style="padding:0 0 15px;">您确定要归档该客户吗？</p>
          <div style="text-align: right; margin: 0">
            <el-button @click="visible = false" size="mini" type="text">取消</el-button>
            <el-button @click="visible = false" size="mini" type="primary">确定</el-button>
          </div>
          <el-button
            @click="setStatus(2)"
            slot="reference"
            type="danger"
            v-if="form.status == 1"
          >归档客户</el-button>
          <el-button
            @click="setStatus(1)"
            slot="reference"
            type="primary"
            v-if="form.status == 2"
          >启用客户</el-button>
        </el-popover>
      </div>
      <div v-else>
        <el-button @click="submitForm('form')" type="primary">提交</el-button>
        <el-button @click="resetForm('form')">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      } else {
        const reg = /^1\d{10}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号码'))
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        // let pattern = new RegExp('^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$')
        // if (!pattern.test(value)) {
        //   callback(new Error('密码由8-20位字母和数字组成'))
        // }
        if (value.length < 8 || value.length > 20) {
          callback(new Error('密码由8-20位字母或数字组成'))
        }
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    return {
      visible: false,
      type: 'add', // add  edit  view
      base: {
        drawer: false, //抽屉开关
        type: 'view'
      },
      myHeaders: {
        token: localStorage.getItem('x-auth-token')
      },
      myData: {
        type: 'logo'
      },
      form: {
        name: '',
        key: '',
        logo: '',
        expired_date: '0000-00-00',
        tmp_expired_date: '',
        memo: '',
        mobile: '',
        password: ''
      },
      rules: {
        name: [{ required: true, message: '必需填写', trigger: 'blur' }],
        key: [{ required: true, message: '必需填写', trigger: 'blur' }],
        tmp_expired_date: [
          { required: true, message: '必需填写', trigger: 'change' }
        ],
        mobile: [{ validator: checkMobile, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }]
      }
    }
  },
  methods: {
    //新增
    add() {
      this.type = 'add'
      this.visible = true
    },
    //查看
    view(site_id) {
      this.type = 'view'
      this.visible = true
      this.$axios
        .post('/site/detail', {
          site_id: site_id
        })
        .then(res => {
          if (res.data.code == 200) {
            this.form = res.data.data
            this.form.tmp_expired_date = res.data.data.expired_date
          }
        })
      // this.form.name = data.name
      // this.form.key = data.key
      // this.form.logo = data.logo
      // this.form.expired_date = data.expired_date
      // this.form.tmp_expired_date = data.expired_date
      // this.form.memo = data.memo
      // this.form.site_id = data.site_id
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let url = this.type == 'add' ? '/site/create' : '/site/edit'
          this.$axios.post(url, this.form).then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.type = 'view'
              this.$parent.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.visible = false
    },
    dateChange() {
      if (
        this.form.tmp_expired_date == '' ||
        this.form.tmp_expired_date == null
      ) {
        this.form.expired_date = '0000-00-00'
      } else {
        this.form.expired_date = this.form.tmp_expired_date
      }
    },
    uploadSuccess(response, file, fileList) {
      this.form.logo = response.data.file_url
    },
    setStatus(status) {
      this.$confirm('此操作将归档 ' + this.form.name + ' , 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post('/site/set_status', {
              site_id: this.form.site_id,
              status: status
            })
            .then(res => {
              if (res.data.code == 200) {
                this.form.status = status
                this.$parent.getListData()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
.jue-drawer-body {
  padding: 40px 40px 0;
}

.form-footer {
  text-align: right;
  width: 600px;
  padding: 0 40px 0 0;
  el-button {
    margin-left: 20px;
  }
}
.form-avatar {
  /deep/ .el-form-item__content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ .el-icon-user-solid {
      font-size: 30px;
    }
  }
  .btn {
    width: 80px;
    text-align: center;
    padding: 10px 0;
  }
}
</style>