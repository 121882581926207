<template>
  <div class="main" data-title="子站列表" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">子站列表</h2>
        <div class="page-header-desc">创建与管理客户系统，功能与本系统相同</div>
      </div>
    </div>

    <el-card class="main-content search-box" shadow="hover">
      <el-input
        @keyup.enter.native="getListData"
        class="input-with-select"
        placeholder="请输入内容"
        style="width: 500px;"
        v-model="key.name"
      >
        <el-select
          @change="getListData"
          placeholder="请选择"
          slot="prepend"
          style="width: 100px;"
          v-model="key.status"
        >
          <el-option label="全部" value="0"></el-option>
          <el-option label="服务中" value="1"></el-option>
          <el-option label="已归档" value="2"></el-option>
        </el-select>
        <el-button @click="getListData" icon="el-icon-search" slot="append"></el-button>
      </el-input>
    </el-card>

    <el-card class="main-content" shadow="hover">
      <el-row :gutter="20" class="list">
        <el-col :key="index" :span="8" v-for="(item, index) in listData">
          <el-card :body-style="{ padding: '15px' }" class="cs-item" shadow="hover">
            <i class="el-icon-jue-yiguidang" v-if="item.status == 2"></i>
            <div class="items">
              <!-- <img :src="item.logo" class="logo" /> -->
              <div class="logo-box">
                <el-avatar :size="50" :src="item.logo" class="logo" icon="el-icon-picture-outline"></el-avatar>
              </div>

              <div class="content">
                <div class="head">
                  <div class="head-body">
                    <div class="title">{{item.name}}</div>
                    <div class="desc">
                      <i class="el-icon-time"></i> 到期时间：
                      <strong>{{item.expired_date}}</strong>
                    </div>
                  </div>
                  <div @click="view(item.site_id)" class="head-footer">
                    <i class="el-icon-view"></i>
                  </div>
                </div>
                <div class="body">{{item.memo}}</div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8" v-has="'site-create'">
          <el-card
            :body-style="{ padding: '15px' }"
            @click.native="add"
            class="add-new"
            shadow="hover"
          >
            <i class="el-icon-plus"></i> 添加新客户
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <subsite-edit ref="subsiteEdit"></subsite-edit>
  </div>
</template>
<script>
import SubsiteEdit from '@/components/SubsiteEdit.vue'
export default {
  components: {
    SubsiteEdit
  },
  data() {
    return {
      key: {
        status: '',
        name: ''
      },
      listData: []
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    view(id) {
      this.$refs.subsiteEdit.view(id)
    },
    add() {
      this.$refs.subsiteEdit.add()
    },
    getListData() {
      this.$axios
        .post('/site/lists', {
          status: this.key.status == '' ? 0 : this.key.status,
          name: this.key.name
        })
        .then(res => {
          if (res.data.code == 200) {
            this.listData = res.data.data
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
.search-box {
  display: flex;
  justify-content: center;
}

.add-new {
  min-height: 137px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.55);
  &:hover {
    color: rgba(0, 0, 0, 0.65);
  }
}

.items {
  display: flex;
  &:hover {
    .content .head .head-footer {
      display: block;
    }
  }
  .logo {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ .el-icon-picture-outline {
      font-size: 30px;
    }
  }
  .content {
    flex: 1;
    .head {
      display: flex;
      .head-body {
        flex: 1;
        .title {
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
        }
        .desc {
          font-size: 12px;
          margin-top: 5px;
        }
      }
      .head-footer {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.55);
        display: none;
      }
    }
    .body {
      font-size: 12px;
      padding: 12px 0 0;
      color: rgba(0, 0, 0, 0.55);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      min-height: 63px;
    }
  }
}
.text-desc {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}

.img {
  width: 50px;
  height: 50px;
}

.list {
  .el-col {
    margin-bottom: 20px;
  }
}
.cs-item {
  position: relative;
  .el-icon-jue-yiguidang {
    position: absolute;
    font-size: 100px;
    color: #ed7760;
    left: 110px;
    top: -10px;
  }
}
</style>